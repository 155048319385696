.offcanvas-title {
  font-size: 22px;
  font-weight: 700;
}

.offcanvas-option,
.offcanvas-resume,
.offcanvas-prospect-add {
  @include mq($from: mobile) {
    --bs-offcanvas-padding-x: 60px
  }
}

.offcanvas-option-lot {
  .title {
    font-size: 26px;
    font-weight: 700;

    @include mq($from: mobile) {
      font-size: 32px;
    }

    span {
      font-weight: 400;
    }
  }
}

.offcanvas-option-prospect {
  .btn {
    display: block;
    margin: 30px auto;
  }

  .declare-prospect {
    text-decoration: none;
  }
}

.offcanvas-body {
  .content-list {
    background-color: var(--bs-gray-100);
    border-radius: 20px;
    list-style-type: none;
    margin: 25px 0 30px;
    padding: 40px;

    a {
      --bs-link-color: var(--bs-body-color);

      text-decoration: none;
    }
  }

  .content-item {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;

    @include mq($from: mobile-xs) {
      gap: 46px;
    }
  }

  .content-label {
    font-weight: 700;
    margin-bottom: 0;
    min-width: 100px;
  }

  .content-value {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 0;
    max-width: calc(100% - 110px);
    word-wrap: break-word;
  }
}

.offcanvas-filters {
  .offcanvas-body {
    align-items: center;
    gap: 10px;
  }
}
