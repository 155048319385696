.program-desc {
  margin-bottom: 60px;

  @include mq($from: wide) {
    align-items: start;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .text {
    @include mq($from: wide) {
      padding-right: 40px;
    }

    @include mq($from: extrawide) {
      padding-right: 80px;
    }
  }

  .address {
    display: flex;
    font-size: 18px;
    font-weight: 700;
    gap: 9px;
    line-height: 30px;

    .icon {
      color: var(--bs-secondary);
      margin-top: 5px;
      min-width: 20px;
    }
  }

  .desc {
    margin-bottom: 30px;
    padding-inline: 30px;
  }

  .medias {
    display: flex;
    flex-direction: column;
    gap: 20px 33px;
    padding-inline: 30px;

    @include mq($from: tablet) {
      flex-direction: row;
    }

    @include mq($from: wide) {
      padding-inline: 0;
    }
  }

  .media-link {
    aspect-ratio: 30/19;
    border-radius: 10px;
    box-shadow: 20px 30px 30px rgba(2, 26, 15, .2);
    color: var(--bs-white);
    max-width: 100%;
    overflow: hidden;
    position: relative;

    @include mq($from: wide) {
      min-width: 307px;
    }
  }

  .more-img {
    align-items: center;
    background-color: rgba(0, 0, 0, .65);
    display: flex;
    font-size: 26px;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
