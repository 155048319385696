.header {
  align-items: center;
  background-color: var(--bs-secondary);
  display: grid;
  min-height: calc(var(--header-height) + var(--content-radius));
  padding-bottom: var(--content-radius);
  position: relative;

  @include mq($from: tablet) {
    display: block;
    padding-top: 8px;

    &::after {
      aspect-ratio: 1;
      background-color: #2b8156;
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      right: -120px;
      top: 10px;
      width: 600px;
    }
  }

  .logo {
    aspect-ratio: 124/46;
    position: relative;
    width: 124px;
    z-index: 1;

    @include mq($from: tablet) {
      width: 160px;
    }
  }

  &.header-login {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: calc(var(--header-login-height) + var(--content-radius));

    .logo {
      @include mq($from: desktop) {
        width: 260px;
      }
    }
  }
}

.header-title {
  align-items: center;
  color: var(--bs-white);
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  font-weight: 700;
  gap: 5px;
  justify-content: center;
  text-align: center;
  text-wrap: balance;

  @include mq($from: tablet) {
    font-size: 28px;
    grid-column: span 2;
  }

  @include mq($from: desktop) {
    font-size: 40px;
  }

  &.home {
    display: block;

    @include mq($until: tablet) {
      font-size: 18px;
    }
  }

  .page-notifs & {
    gap: 10px;

    @include mq($from: desktop) {
      gap: 18px;
    }

    .icon {
      height: 29px;
      min-width: 23px;
      width: 23px;
    }
  }

  span {
    font-weight: 400;
  }

  .lots {
    background-color: var(--bs-white);
    border-radius: 40px;
    color: var(--bs-secondary);
    font-size: 14px;
    font-weight: 400;
    padding: 6px 15px;
    text-decoration: none;

    @include mq($from: tablet) {
      margin-left: 20px;
    }
  }
}
