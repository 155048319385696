.program-card-grid {
  display: grid;
  gap: 10px;

  @include mq($from: tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($from: desktop) {
    gap: 20px
  }

  @include mq($from: wide) {
    gap: 28px 30px
  }
}

.program-card {
  background-color: var(--bs-white);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 10px 10px 30px;
  text-decoration: none;
  transition: all .5s;

  @include mq($from: desktop) {
    flex-direction: column;
    padding: 0 0 30px;

    &:hover {
      box-shadow: 0 2px 34px rgba(0, 0, 0, .39);
    }
  }

  @include mq($from: wide) {
    gap: 22px;
  }

  .media {
    position: relative;
  }

  .flag {
    background-color: rgba(var(--bs-secondary-rgb), .61);
    border-radius: 50px;
    color: var(--bs-white);
    font-size: 10px;
    font-weight: 500;
    left: 10px;
    padding: 6px 10px;
    position: absolute;
    top: 10px;

    @include mq($from: wide) {
      background-color: var(--bs-secondary);
      font-size: 14px;
      left: 30px;
      top: 30px;
    }
  }

  .img {
    aspect-ratio: 1;
    border-radius: 8px;
    object-fit: cover;
    transition: all .2s;
    width: 130px;

    @include mq($from: desktop) {
      aspect-ratio: 21/12;
      border-radius: 0;
      width: 100%;
    }
  }

  .infos {
    @include mq($from: desktop) {
      padding-inline: 15px;
    }

    @include mq($from: wide) {
      padding-inline: 30px;
    }
  }

  .title {
    color: $green-700;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 3px;
  }

  .address {
    color: var(--bs-gray-600);
    line-height: 22px;
    margin-bottom: 0;
  }

  .metas {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px 10px;
    margin-bottom: 0;

    @include mq($from: desktop) {
      margin-top: 8px;
    }
  }

  .lots {
    background-color: var(--bs-secondary);
    border-radius: 50px;
    color: var(--bs-white);
    padding: 6px 10px;
  }

  .types {
    color: var(--bs-body-color);
    font-weight: 700;
    white-space: nowrap;

    @include mq($from: desktop) {
      font-size: 16px;
    }
  }

  .arrow-link {
    width: 100%;

    @include mq($from: extrawide) {
      margin-left: auto;
      width: auto;
    }
  }
}
