.programs-tabs {
  margin-bottom: 10px;
  overflow: auto;
  padding-bottom: 10px;
  scroll-snap-type: x mandatory;

  @include mq($from: desktop) {
    margin-bottom: 25px;
  }
}

.home-grid {
  @include mq($from: desktop) {
    align-items: start;
    display: grid;
    gap: 30px;
    grid-template-columns: 7fr 4fr;
  }

  @include mq($from: wide) {
    grid-template-columns: 2fr minmax(0, 1fr);
  }
}

.home-programs {
  @include mq($from: desktop) {
    order: -1;
  }
}
