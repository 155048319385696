.lots-wrapper {
  background-color: var(--bs-white);
  border-radius: 20px;
  margin-top: 22px;
  padding: 36px 30px;

  @include mq($from: desktop) {
    padding: 60px 38px;
  }

  th {
    white-space: nowrap;
  }

  tr {
    th:nth-child(8) {
      min-width: 150px;
      width: 150px;
    }

    th:last-child {
      min-width: 270px;
    }
  }

  .sortable-column a {
    color: var(--bs-secondary);
    text-decoration: none;
  }
}

.description-wrapper {
  @include mq($from: desktop) {
    padding-inline: 230px;
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    border-radius: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--bs-gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--bs-secondary);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--bs-secondary);
  }

  tr {
    vertical-align: middle;

    th:not(:first-child),
    td:not(:first-child) {
      text-align: center;
    }
  }

  tbody {
    tr:not(.table-resume):not(:last-child) {
      border-bottom: 1px solid var(--bs-gray-500);
    }
  }

  .table-resume td {
    color: var(--bs-secondary);
    font-size: 16px;
    font-weight: 500;
    padding-top: 36px;
  }

  .optioned td {
    color: var(--bs-gray-600)
  }
}

.no-lots {
  align-items: center;
  color: var(--bs-secondary);
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  gap: 20px;
  justify-content: center;
  line-height: 30px;
  margin: 0 auto;
  max-width: 460px;
  padding: 80px 30px 100px;
  text-align: center;
  text-wrap: balance;

  @include mq($from: desktop) {
    font-size: 30px;
    line-height: 41px;
    padding: 110px 0 160px;
  }
}

.table {
  --bs-table-bg: transparent;
}
