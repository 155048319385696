.content-login {
  .h1 {
    color: var(--bs-secondary);
    font-weight: 700;
    margin-bottom: 20px;

    @include mq($from: desktop) {
      margin-bottom: 0;
    }
  }
}

.login-content {
  margin: 0 auto;
  max-width: 460px;
}

.login-form {
  padding-inline: 25px;

  [type='submit'] {
    display: block;
    margin: 30px auto 0;
  }

  [type="password"] {
    + .form-text {
      font-size: 14px;
      margin-top: 18px;
      text-align: right;

      @include mq($from: desktop) {
        margin-top: 24px;
      }

      /* stylelint-disable selector-max-compound-selectors */
      ul {
        text-align: left;
      }
    }
  }

  a {
    --bs-link-color: var(--bs-body-color);

    text-align: right;
    text-decoration: underline;
  }
}

.login-header,
.login-footer {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-wrap: pretty;
}

.login-footer {
  margin-top: 20px;
}
