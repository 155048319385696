.title-notif {
  display: flex;
  height: 29px;
  position: relative;

  .notif-available {
    right: 0;
    top: 0;
    width: 4px;
  }
}

.notifs-wrapper {
  @include mq($from: desktop) {
    background-color: var(--bs-white);
    border-radius: 20px;
    padding: 40px 60px;
  }

  @include mq($from: wide) {
    margin-top: 60px;
    padding-inline: 90px;
  }

  @include mq($from: extrawide) {
    padding-inline: 120px;
  }
}

.notifs-by-date {
  + .notifs-by-date {
    margin-top: 40px;

    @include mq($from: wide) {
      margin-top: 70px;
    }
  }

  .date {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}

.notifs-list {
  font-size: 14px;
  line-height: 24px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include mq($from: tablet) {
    font-size: 16px;
  }
}

.notifs-list-item {
  border-bottom: 1px solid var(--bs-gray-400);
  margin-bottom: 20px;
  padding-bottom: 20px;

  @include mq($from: tablet) {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
}

.notif-title {
  &.refused {
    color: #7c2b2b;
  }
}
