.btn {
  @include mq($from: tablet) {
    --bs-btn-padding-x: 30px;
    --bs-btn-padding-y: 15px;
  }
}

.btn-sm {
  --bs-btn-padding-x: 16px;
  --bs-btn-padding-y: 8px;

  white-space: nowrap;
}

.btn-primary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-disabled-color: var(--bs-white);
}

.btn-outline-primary {
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
}

.btn-light {
  --bs-btn-bg: var(--bs-gray-200);
  --bs-btn-border-color: var(--bs-gray-200);
  --bs-btn-disabled-bg: var(--bs-gray-200);
  --bs-btn-disabled-border-color: var(--bs-gray-200);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
}

.btn-optioned {
  align-items: center;
  border-radius: 10px;
  display: flex;
  font-weight: 700;
  gap: 20px;
  justify-content: space-between;
  padding: 18px 20px;

  &[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.btn-white {
  @include button-variant(
    $white,
    $white,
    $green-700,
    $white,
    $white,
    $primary,
    $white,
    $white,
    $primary
  );
}

.fake-select {
  align-items: center;
  border-radius: 10px;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  max-width: 100%;
  width: 320px;

  .icon {
    height: 5px;
    width: 9px;
  }

  + .dropdown-menu {
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05);
    min-width: 100%;
  }
}

.fake-select-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  a {
    --bs-link-color: var(--bs-body-color);

    display: block;
    padding-block: 4px;
    text-decoration: none;
  }
}

.btn-close {
  align-items: center;
  color: var(--bs-secondary);
  display: flex;
  gap: 27px;
  text-decoration: none;

  span {
    @include mq($until: tablet) {
      display: none;
    }
  }
}

.btn-logout {
  background: none;
  border: 0;
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}
