@use 'sass:map';
@import '~bootstrap/scss/functions';
@import 'sprites';

@each $index, $value in $sprites {
  $size: map.get($sizes, $index);

  .icon-#{$index} {
    @include sprite($index);
  }

  .icon-#{$index}-dims {
    height: map.get($size, 'height');
    width: map.get($size, 'width');
  }
}

@import 'config/config';
@import 'vendors/vendors';
@import 'layout/layout';
@import 'component/component';
