.nav-tabs {
  flex-wrap: nowrap;
  gap: 20px;
  padding-inline: 15px;
  width: fit-content;

  .page-program & {
    justify-content: center;
    width: auto;

    @include mq($from: mobile) {
      justify-content: flex-start;
    }

    .nav-link {
      @include mq($from: mobile) {
        font-weight: 600;
      }
    }

    .nav-link-infos {
      display: none;
      font-weight: 400;

      @include mq($from: mobile) {
        display: inline;
      }
    }
  }

  @include mq($from: mobile) {
    padding-inline: 0;
    width: auto;
  }

  @include mq($from: tablet) {
    gap: 40px;
  }

  @include mq($from: desktop) {
    gap: 60px;
  }

  .nav-link {
    --bs-nav-link-color: var(--bs-black);

    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 0 0 12px;
    white-space: nowrap;

    @include mq($from: tablet) {
      font-size: 18px;
      font-weight: 400;
    }

    &.active {
      background-color: transparent;

      &::after {
        background-color: var(--bs-primary);
        border-radius: 3px;
        bottom: -3px;
        content: '';
        display: block;
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
