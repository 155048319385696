.prospects-wrapper {
  background-color: var(--bs-white);
  border-radius: 20px;
  margin-top: 22px;
  padding: 36px 30px;

  @include mq($from: desktop) {
    padding: 60px 38px;
  }

  th,
  td {
    white-space: nowrap;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
      text-align: right;
    }

    .btn-link {
      --bs-btn-hover-color: var(--bs-secondary);
    }

    .icon-chevron-dims {
      height: 10px;
      transform: rotate(-90deg);
      width: 18px;
    }

    &:not(:has(.btn)) td {
      padding-block: 32px;
    }
  }

  .active {
    color: var(--bs-secondary);
  }

  .refused {
    color: #7c2b2b;
  }

  .progress {
    color: var(--bs-gray-400);
    font-style: italic;
  }

  .tel {
    --bs-link-color: var(--bs-body-color);

    text-decoration: none;
  }
}
