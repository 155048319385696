body {
  --header-height: 170px;
  --header-login-height: 120px;
  --content-radius: 20px;

  min-height: 100vh;
  overflow-x: hidden;

  @include mq($from: desktop) {
    --header-height: 245px;
    --header-login-height: 245px;
    --content-radius: 40px;
  }
}

h1,
.h1 {
  @include mq($from: tablet) {
    font-size: 36px;
  }
}

a {
  color: var(--bs-link-color);
  transition: color .2s;

  &:hover,
  &:focus {
    color: var(--bs-link-hover-color);
  }
}

.arrow-link {
  align-items: center;
  display: flex;
  gap: 10px;
  text-decoration: none;

  &:hover {
    .icon {
      transform: translateX(5px);
    }
  }

  .icon {
    transition: transform .5s;
  }

  span {
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: .2em;
  }
}

.notif-available {
  aspect-ratio: 1;
  background-color: var(--bs-primary);
  border-radius: 50%;
  display: block;
  position: absolute;
  width: 13px;
}

.content {
  background-color: var(--bs-gray-100);
  border-radius: var(--content-radius) var(--content-radius) 0 0;
  margin-top: calc(-1 * var(--content-radius));
  min-height: calc(100vh - var(--header-login-height));
  padding-block: 40px 100px;
  position: relative;

  @include mq($from: desktop) {
    padding-block: 65px;
  }
}

.grid-more {
  display: block;
  margin: 40px auto;
  width: fit-content;

  @include mq($from: desktop) {
    margin-block: 60px;
  }
}

.success-message {
  color: var(--bs-secondary);
  font-size: 18px;
  font-weight: 700;
  margin-top: 60px;
  text-align: center;
}
