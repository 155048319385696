.nav-desktop {
  display: grid;
  gap: 20px;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: 1;

  @include mq($from: tablet) {
    grid-template-columns: auto minmax(0, 1fr);
    justify-content: start;
    text-align: left;
  }

  @include mq($from: desktop) {
    gap: 40px 20px;
  }
}

.nav-links {
  align-items: center;
  display: none;
  gap: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include mq($from: tablet) {
    display: flex;
    margin-left: auto;
  }

  @include mq($from: desktop) {
    gap: 30px;
  }

  .btn-light {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 38px;
    justify-content: center;
    padding: 5px 26px;
    white-space: nowrap;
  }

  .nav-notif {
    .btn-light {
      padding: 0;
      position: relative;
      width: 38px;
    }
  }
}

.nav-item {
  position: relative;
}

.nav-link {
  --bs-nav-link-color: var(--bs-white);
  --bs-nav-link-hover-color: var(--bs-black);

  font-size: 16px;
  text-decoration: none;
}

.nav-notif {
  .icon-ring-dims {
    height: 20px;
    width: 16px;
  }

  .notif-available {
    bottom: 0;
    right: -3px;
  }
}

.nav-account {
  .icon-user-dims {
    height: 18px;
    width: 18px;
  }

  .icon-chevron-dims {
    height: 5px;
    margin-left: 3px;
    width: 9px;
  }
}

.dropdown-menu {
  box-shadow: 0 2px 34px rgba(0, 0, 0, 0.39);
  overflow: hidden;
}
