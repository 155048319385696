.home-optioned-lots {
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, .05);
  margin-bottom: 15px;
  overflow: hidden;

  @include mq($from: desktop) {
    border-radius: 20px;
    box-shadow: 20px 30px 30px rgba(2, 26, 15, .2);
  }

  .btn-optioned {
    width: 100%;

    @include mq($from: desktop) {
      display: none;
    }
  }

  .collapse-optioned {
    @include mq($from: desktop) {
      display: block;
    }

    .btn {
      margin: 36px auto 0;
      width: fit-content;
    }
  }

  .block-title {
    display: none;

    @include mq($from: desktop) {
      color: $green-700;
      display: block;
      font-weight: 700;
      margin-bottom: 34px;
    }
  }

  .card {
    @include mq($from: extrawide) {
      padding: 46px 36px;
    }
  }
}

.optioned-list-header {
  border-bottom: 1px solid var(--bs-gray-300);
  color: var(--bs-secondary);
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 0;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.optioned-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.optioned-item {
  --bs-link-color: var(--bs-body-color);

  align-items: flex-end;
  border-bottom: 1px solid var(--bs-gray-300);
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding-block: 16px;
  text-decoration: none;
  transition: all .5s;

  &:hover {
    background-color: var(--bs-gray-100);

    @include mq($from: wide) {
      padding-inline: 10px;
    }
  }
}

.optioned-lot {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;

  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
}

.optioned-validity {
  align-items: center;
  color: var(--bs-secondary);
  display: flex;
  gap: 10px;
  margin-bottom: 0;

  .icon {
    transform: rotate(-90deg);
  }
}
