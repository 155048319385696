.offcanvas-filters {
  &.show {
    .fake-select .icon {
      display: none;
    }

    .dropdown-menu {
      box-shadow: none;
      display: block;
      position: relative !important;
      transform: none !important;
    }

    .card {
      --bs-card-spacer-y: 0;
    }
  }
}

.filter-content + .filter-content {
  margin-top: 30px;

  @include mq($from: tablet) {
    margin-left: 10px;
    margin-top: 0;
  }
}
