.form-label {
  margin-bottom: 0;
}

.form-control,
.form-select {
  border-width: 0 0 1px;
}

.form-input {
  margin-top: 18px;

  @include mq($from: tablet) {
    margin-top: 27px;
  }
}

.input-group {
  align-items: center;
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .btn-input {
    --bs-link-color: var(--bs-body-color);
    --bs-btn-padding-x: 10px;
    --bs-btn-padding-y: 0;
    --bs-btn-border-radius: 0;
    --bs-btn-border-width: 0;

    position: absolute;
    right: 0;
    z-index: 10;
  }
}

.pwd-icon,
.text-icon {
  display: none;
  height: 22px;
  opacity: .6;
  width: 22px;
}

[type='password'] + .btn-input {
  .pwd-icon {
    display: block;
  }
}

[type='text'] + .btn-input {
  .text-icon {
    display: block;
  }
}

.required-info {
  margin-block: 26px 0;
}

.form-add-prospect {
  .form-label {
    font-size: 16px;
    margin-top: 20px;

    @include mq($from: tablet) {
      color: var(--bs-body-color);
      font-weight: 600;
      margin-top: 32px;
    }
  }

  .btn {
    display: block;
    margin: 47px auto 0;

    @include mq($from: tablet) {
      margin-top: 66px;
    }
  }
}

.form-grid {
  @include mq($from: mobile) {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
