.nav-notifs-list {
  list-style-type: none;
  padding: 0;

  + .arrow-link {
    justify-content: flex-end;
    margin-top: 46px;
  }
}

.notif-item {
  display: flex;
  gap: 19px;
  padding-bottom: 14px;

  &.is-unread::before {
    background-color: var(--bs-primary);
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    margin-top: 6px;
    min-width: 8px;
    width: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--bs-gray-300);
    margin-bottom: 18px;
  }

  p {
    margin-bottom: 0;
  }
}

.notif-infos {
  line-height: 22px;
  overflow: hidden;
}

.notif-title {
  color: var(--bs-secondary);
  font-weight: 700;
}

.notif-metas {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.notif-when {
  align-self: flex-end;
  color: var(--bs-gray-400);
  font-size: 12px;
  margin-left: auto;
  white-space: nowrap;
}
