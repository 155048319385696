.nav-mobile {
  align-items: center;
  background-color: var(--bs-white);
  border-radius: 20px;
  bottom: 20px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-around;
  left: 15px;
  padding-block: 9px;
  position: fixed;
  width: calc(100% - 30px);
  z-index: 1000;

  @include mq($from: tablet) {
    display: none;
  }
}

.mobile-link {
  --bs-link-color: var(--bs-secondary);
  --bs-link-hover-color: var(--bs-secondary);

  position: relative;

  &.home {
    align-items: center;
    aspect-ratio: 1;
    background-color: var(--bs-secondary);
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .13);
    color: var(--bs-white);
    display: flex;
    justify-content: center;
    width: 48px;
  }

  .notif-available {
    right: 0;
    top: 0;
    width: 4px;
  }
}
