/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v40-latin-regular.woff2') format('woff2');
}

/* open-sans-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/open-sans-v40-latin-500.woff2') format('woff2');
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans-v40-latin-600.woff2') format('woff2');
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v40-latin-700.woff2') format('woff2');
}
