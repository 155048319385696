.folders {
  display: grid;
  gap: 10px 30px;

  @include mq($from: tablet) {
    gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq($from: desktop) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include mq($from: wide) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.hide {
    display: none;
  }
}

.folder-link {
  --bs-link-color: var(--bs-secondary);

  background-color: var(--bs-white);
  border-radius: 10px;
  display: block;
  padding: 38px 30px;
  text-decoration: none;
  transition: all .5s;

  &:hover {
    box-shadow: 0 2px 34px rgba(0, 0, 0, 0.39);
  }
}

.folder-link-trigger {
  text-decoration: none;
}

.breadcrumb-link {
  align-items: center;
  display: flex;
}

.folder-title {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  gap: 17px;
  margin-bottom: 18px;

  .icon {
    height: 32px;
    min-width: 42px;
    width: 42px;
  }
}

.folder-last-modification {
  color: var(--bs-gray-500);
  margin-bottom: 0;
}

.folder-content {
  --bs-link-color: var(--bs-secondary);

  background-color: var(--bs-white);
  border-radius: 10px;
  display: none;
  padding: 38px 20px;

  @include mq($from: mobile) {
    padding-inline: 30px;
  }

  &.show {
    display: block;
  }

  .folder-title {
    color: var(--bs-secondary);
    flex-wrap: wrap;
    font-weight: 400;
    gap: 0;
  }

  .icon-chevron-dims {
    height: 10px;
    transform: rotate(-90deg);
    width: 18px;
  }
}

.back-folders {
  margin-left: 17px;
  text-decoration: none;
}

.documents {
  list-style-type: none;
  margin: 50px 0 0;
  padding: 0;
}

.document {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--bs-gray-400);
    margin-bottom: 20px;
    padding-bottom: 20px;

    @include mq($from: desktop) {
      margin-bottom: 26px;
      padding-bottom: 26px;
    }
  }
}

.document-picto {
  margin-bottom: 0;
  min-width: 30px;
  width: 30px;

  .icon-folder-dims {
    height: 19px;
    width: 22px;
  }
}

.document-metas {
  @include mq($from: desktop) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .name {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.document-actions {
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-secondary);

  display: flex;
  gap: 40px;
  justify-content: center;
  width: 100%;

  @include mq($from:tablet) {
    margin-left: auto;
    width: auto;
  }

  a {
    align-items: center;
    display: flex;
    gap: 12px;
    text-decoration: none;
  }
}
